var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    _vm._b(
      {
        staticClass: "mb-4 dashboard-top-bar",
        class: { "mt-6": _vm.barType !== "dashboard" },
        attrs: { flat: "", dense: "", color: "transparent" },
      },
      "v-app-bar",
      (_vm.$props, _vm.$attrs),
      false
    ),
    [
      _c("AppLogo", { attrs: { "data-test-id": _vm.logo, width: "90px" } }),
      _c("v-spacer"),
      _vm.barType === "dashboard"
        ? _c(
            "v-menu",
            {
              attrs: { bottom: "", left: "", "offset-y": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "div",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "pl-0 pr-0",
                                attrs: {
                                  text: "",
                                  ripple: false,
                                  elevation: "0",
                                },
                              },
                              "div",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "hidden-sm-and-down text-body-2 cursor-pointer mr-2",
                                attrs: {
                                  "data-test-id": "dashboard-my-account",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("dashboard.pages.myAccount.title")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("AppAvatar", {
                              attrs: {
                                "data-test-id": _vm.avatar,
                                size: 40,
                                text: _vm.getFullName,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                4131157883
              ),
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "profile-menu-list",
                  attrs: {
                    "data-test-id": "dashboard-profile-menu",
                    dense: "",
                    "min-width": "150",
                  },
                },
                [
                  _vm.user
                    ? _c(
                        "v-list-item",
                        { attrs: { "data-test-id": "dashboard-PM-full-name" } },
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.getFullName)),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider"),
                  _vm._l(_vm.profileMenuItems, function (item, i) {
                    return _c(
                      "v-list-item",
                      {
                        key: i,
                        staticClass: "mt-1",
                        attrs: {
                          "data-test-id": `dashboard-PM-${item.dataSetId}`,
                          to: item.path,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.clickEvent(item)
                          },
                        },
                      },
                      [
                        item.dataSetId !== "version"
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "font-weight-regular" },
                              [_vm._v(" " + _vm._s(_vm.$t(item.title)) + " ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "version",
                      attrs: { "data-test-id": "dashboard-PM-version" },
                    },
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "version-title font-weight-regular" },
                        [_vm._v(_vm._s(_vm.version))]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }