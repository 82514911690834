<template>
  <v-app-bar flat dense color="transparent" class="mb-4 dashboard-top-bar" :class="{ 'mt-6': barType !== 'dashboard' }" v-bind="($props, $attrs)">
    <AppLogo :data-test-id="logo" width="90px" />
    <v-spacer></v-spacer>
    <!-- <v-btn text to="upgrade" small class="mr-2">{{ $t('upgradeText') }}</v-btn> -->
    <v-menu v-if="barType === 'dashboard'" bottom left offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div text :ripple="false" v-bind="attrs" v-on="on" class="pl-0 pr-0" elevation="0">
          <span data-test-id="dashboard-my-account" class="hidden-sm-and-down text-body-2 cursor-pointer mr-2">
            {{ $t('dashboard.pages.myAccount.title') }}
          </span>
          <AppAvatar :data-test-id="avatar" :size="40" :text="getFullName" />
        </div>
      </template>

      <v-list data-test-id="dashboard-profile-menu" dense min-width="150" class="profile-menu-list">
        <v-list-item v-if="user" data-test-id="dashboard-PM-full-name">
          <v-list-item-title>{{ getFullName }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          v-for="(item, i) in profileMenuItems"
          :data-test-id="`dashboard-PM-${item.dataSetId}`"
          :key="i"
          class="mt-1"
          :to="item.path"
          @click.stop="clickEvent(item)"
        >
          <v-list-item-title class="font-weight-regular" v-if="item.dataSetId !== 'version'">
            {{ $t(item.title) }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="version" data-test-id="dashboard-PM-version">
          <v-list-item-title class="version-title font-weight-regular">{{ version }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { version } from '../../package';

export default {
  name: 'TopBar',
  props: {
    barType: {
      type: String,
      default: 'dashboard',
    },
  },
  data() {
    return {
      profileMenuItems: [
        // {
        //   title: 'dashboard.pages.myAccount.title',
        //   path: '/account',
        // },
        {
          title: 'dashboard.pages.myAccount.delete',
          path: '#',
          dataSetId: 'delete',
        },
        {
          title: 'logout',
          path: '/logout',
          dataSetId: 'logout',
        },
      ],
      version: 'v' + version,
    };
  },
  computed: {
    ...mapState('User', ['user']),
    ...mapGetters('User', ['getFullName']),
    logo() {
      return 'dashboard-logo';
    },
    avatar() {
      return 'dashboard-avatar';
    },
  },
  methods: {
    clickEvent(item) {
      if (item.title === 'dashboard.pages.myAccount.delete') {
        this.$router.push('/dashboard/account/delete');
      }
    },
  },
};
</script>

<style lang="scss">
.dashboard-top-bar {
  &.v-app-bar {
    .v-btn {
      text-transform: inherit;
      text-decoration: underline;
    }
  }
}
</style>

